import vuetify from '@common/plugins/vuetify';
import Vue from 'vue';
import "./assets/css/style.scss";
import i18n from './plugins/i18n';
import router from './plugins/router';
import store from './plugins/store';
import './plugins/vuetifyx';
import Layout from './views/Layout.vue';

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(Layout)
}).$mount('#app')
