import Vue from 'vue'
import vuetifyx from '@common/plugins/vuetifyx';

Vue.prototype.$vuetifyx = vuetifyx({
    form: {
        content: {
            ignoreOtherFields: true,
            extraDataFields: ["_id"]
        }
    },
    dataTable: {
        attrs: {
            "fixed-header": true,
        },
        content: {
            // layout: "layout2",
            filters: {
                searchButton: {
                    content: {
                        "icon-pre": ""
                    },
                },
            },
        },
    }
})
